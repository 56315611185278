<template>
  <div class="container">
    <div class="final-exam my-4">

      <h4 class="text-center">Экзамен</h4>

      <div class="my-4">

        <div class="card">
          <div class="card-body">

            <div class="card-title mb-4">
              <h4 class="d-block ml-3">
                Информация о курсе
              </h4>
            </div>

            <div>
              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Название курса:</strong></p></div>
                <div class="col-md-9">
                  {{ finalExam_form.courseInfos?.course_name }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Язык обучения:</strong></p></div>
                <div class="col-md-9">
                  {{ finalExam_form.courseInfos?.formationEducationProgram?.educationDiscipline?.language?.native_name}}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Курс:</strong></p></div>
                <div class="col-md-9">
                  {{ finalExam_form.courseInfos?.educationCurriculum?.study_course }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Семестр:</strong></p></div>
                <div class="col-md-9">
                  {{ finalExam_form.courseInfos?.formationEducationProgram?.semester }}
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Кредит:</strong></p></div>
                <div class="col-md-9">
                  {{ finalExam_form.courseInfos?.formationEducationProgram?.credit }}
                </div>
              </div>


              <div class="row mt-4">
                <div class="col-md-3"><p><strong>Группы</strong></p></div>
                <div class="col-md-9">
                  <select class="form-control form-select" @input="changeGroup($event)">
                    <option
                        v-for="(item, index) in [{id: 0, name: 'Выберите группу'}, ...finalExam_form.studentGroups]"
                        :value="item.id"
                        :selected="item.id === groupId"
                        :key="index">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              
            </div>

          </div>
        </div>

        <div class="attendance mt-4" v-if="groupId != 0">
          <DataTable :value="filteredStudents" stripedRows responsiveLayout="scroll">

            <Column field="barcode" header="Баркод"></Column>
            <Column header="ФИО">
              <template #body="{data}">
                {{data.last_name}} {{data.first_name}}
              </template>
            </Column>

            <Column header="Экзамен">
              <template #body="{data}">
                <div
                    v-if="finalExam_form.courseInfos?.exam_type_id == 3 || finalExam_form.courseInfos?.exam_type_id == 2">
                  {{ data.student_ratings_exam }}
                </div>
                <div v-else-if="data.student_ratings_status_exam == 0">
                  <input class="form-control" id="number" type="number" max="100" min="0"
                         placeholder="Экзамен"
                         :value="data.student_ratings_exam"
                         @change="changeExamGrade(data.student_ratings_id, $event)">
                </div>
                <div v-else>
                  {{ data.student_ratings_exam }}
                </div>
              </template>
            </Column>

            <!--              <template #footer>-->
            <!--                <Button label="Сохранить" @click="postFinalExam" v-if="filteredStudents[0].student_ratings_status_exam == 0"/>-->
            <!--              </template>-->

          </DataTable>

          <div class="mt-4 text-center" style="min-height: 120px">
            <div
                v-if="((finalExam_form.courseInfos?.exam_type_id == 2) || (finalExam_form.courseInfos?.exam_type_id == 3))">
              Тип экзамена не соответстует для выставления баллов
            </div>
            <div v-else>
              <div v-if="+filteredStudents[0].student_ratings_status_exam===0">
                <button type="button" class="btn btn-primary" @click="postFinalExam">
                  Закрыть ведомость
                </button>
              </div>
              <div v-else>
                Оценка выставлена
              </div>
            </div>
          </div>

        </div>


      </div>


    </div>
  </div>
</template>

<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

  export default {
    name: "FinalExam",
    data() {
      return {
        mdl_course_id: +this.$route.query.mdl_course_id || 1,
        education_courses_id: +this.$route.query.education_courses_id || 1,
        groupId: 0,
        filteredStudents: []
      }
    },
    computed: {
      ...mapState('finalExam', ['finalExam_form']),
    },
    methods: {
      ...mapActions('finalExam', ['GET_EDUCATION_COURSE_DATA_BY_ID', 'GET_STUDENTS_BY_MDL_COURSE_ID', 'POST_FINAL_EXAM_REPORT']),
      ...mapMutations('finalExam', ['SET_FINAL_EXAM_REPORT', 'SET_EDUCATION_COURSES_ID', 'UPDATE_FINAL_EXAM_REPORT', 'CLEAR_FINAL_EXAM_REPORT', 'UPDATE_EXAM_GRADE_REPORT']),
      async changeGroup(e) {
        this.filteredStudents = []
        await this.CLEAR_FINAL_EXAM_REPORT()
        this.groupId = e.target.value
        console.log(this.groupId, 'groupId')

        this.filteredStudents = this.finalExam_form.students.filter(i => i.group_id == this.groupId)

        console.log(this.filteredStudents, 'filteredStudents')
        await this.SET_FINAL_EXAM_REPORT(this.filteredStudents)
      },
      changeExamGrade(student_ratings_id, e) {
        let exam = e.target.value
        this.UPDATE_FINAL_EXAM_REPORT({student_ratings_id, exam})
      },
      async postFinalExam() {
        const res = await this.POST_FINAL_EXAM_REPORT()
        this.groupId = 0
        if (res == true) {
          await this.GET_EDUCATION_COURSE_DATA_BY_ID(this.education_courses_id)
          await this.GET_STUDENTS_BY_MDL_COURSE_ID(this.mdl_course_id)
          this.$message({title: 'Оценка', text: 'Оценки успешно выставлены'});
        } else {
          this.$error({title: 'Оценка', text: 'Произошла ошибка'})
        }
      }
    },
    async mounted() {
      await this.GET_EDUCATION_COURSE_DATA_BY_ID(this.education_courses_id)
      await this.GET_STUDENTS_BY_MDL_COURSE_ID(this.mdl_course_id)
      await this.SET_EDUCATION_COURSES_ID(this.education_courses_id)
      // console.log('studentRating', this.finalExam_form.courseInfos.studentRatings)
    }

  }
</script>

<style scoped>

</style>